import Api from "@/services/api";
import {removeEmptyParams} from "@utils";

export default {
    findAll(filter) {
        return Api().get("/cei2s", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllCurrent(filter) {
        return Api().get("/cei2s/current", {params: removeEmptyParams(filter)}).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findAllByYear(year) {
        return Api().get("/cei2s/year/" + year).then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/cei2/" + id).then(res => this.formatRes(res.data));
    },
    create(cei) {
        return Api().post("/cei2", cei).then(res => this.formatRes(res.data));
    },
    update(cei) {
        return Api().put("/cei2/" + cei.id, cei).then(res => this.formatRes(res.data));
    },
    delete(cei) {
        return Api().delete("/cei2/" + cei.id).then(() => cei);
    },
    formatRes(e) {
        return e;
    }
}